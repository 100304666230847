import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Categories, Category, Header3, Center, Price, colors, mobileThresholdPixels,
  smallMobileThresholdPixels, margins } from './styledComponents';
import { Button } from './Home/v2/StyledComponents';
import { toTwoDigitsOrMore } from '../services/formatting';
import featureSwitch from '../services/featureSwitch';
import isIE from '../services/isIE';
import simpleAlterations from '../services/simpleAlterations';

const Title4 = styled.h4`
  color: ${colors.navy};
  padding: 0 20px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
    padding: 10px;
  }
`;

const AlterationsTextContainer = styled.div`
  flex: 1;
`;

const AddContainer = styled.div`
  ${() => isIE() ? 'width: 80px;' : 'flex: 0;'}
  margin-right: 20px;
  pointer-events: none;
`;

const Add = styled.span`
  pointer-events: auto;
  color: ${colors.navy};
  padding: 10px 10px;
`;

const PriceContainer = styled.div`
  flex: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Choice = styled.div`
  cursor: pointer;
  padding: 10px;
  color: ${colors.navy};
  border-bottom: 1px dotted #333;
  background: ${props => props.isSelected ? colors.sand : 'initial'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
  opacity: 0.9;
  transition: all 0.3s ease;
  font-weight: 300;

  ::selection {
    background: transparent;
  }

  @media (min-width: ${mobileThresholdPixels}) {
    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: ${smallMobileThresholdPixels}) {
    font-size: 0.8em;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 5px 10px;
    opacity: 1;
  }
`;

const Bold = styled.div`
  font-weight: normal;
`;

const Note = styled.div`
  font-size: 0.9em;
`;

const ButtonContainer = styled(Center)`
  margin-top: ${margins.m};
`;

function onChoiceClick(quantity, item, toggleOrSetAlteration) {
  if (item.multiple) {
    toggleOrSetAlteration(item.id, quantity + 1);
  } else {
    toggleOrSetAlteration(item.id);
  }
}

function showItemPrice(item, showLiningPrice, liningPrice) {
  if (featureSwitch('alterations_showSimpleAsOffered') && simpleAlterations.includes(item.id)) {
    return 'Offert';
  }
  return (
    <React.Fragment>
      {!featureSwitch('alterations_hidePrice') &&
        <Price>{toTwoDigitsOrMore(item.price)}€</Price>
      }
      {showLiningPrice &&
        <Price>+{toTwoDigitsOrMore(liningPrice)}€</Price>
      }
    </React.Fragment>
  );
}

function renderItem(item, toggleOrSetAlteration, cloth, mostCommonCategory) {
  const quantity = (cloth.alterations && cloth.alterations[item.id]) || 0;
  const liningsNumber = cloth.liningsNumber || 0;
  const liningUnitPrice = item.liningUnitPrice || 0;
  const liningPrice = (liningUnitPrice * liningsNumber) * quantity;
  const showLiningPrice = !featureSwitch('step2_noLining') && !featureSwitch('alterations_hidePrice');
  return (
    <Choice
      isSelected={quantity}
      onClick={() => onChoiceClick(quantity, item, toggleOrSetAlteration)}
      key={item.id}
    >
      <AddContainer>
        {<Add onClick={(e) => { toggleOrSetAlteration(item.id, quantity - 1); e.stopPropagation(); }}>-</Add>}
        <span>{toTwoDigitsOrMore(quantity)}</span>
        {<Add
          onClick={(e) => { toggleOrSetAlteration(item.id, item.multiple ? quantity + 1 : 1); e.stopPropagation(); }}
        >+</Add>}
      </AddContainer>
      <AlterationsTextContainer>
        <Bold>
          {(mostCommonCategory && item.mostCommonCategoryLabel) ?
            item.mostCommonCategoryLabel.toLocaleLowerCase().replace('a.p.c.', 'A.P.C.') :
            item.name.toLocaleLowerCase().replace('a.p.c.', 'A.P.C.')}
        </Bold>
        {showLiningPrice &&
          <Note>prix de la doublure</Note>
        }
      </AlterationsTextContainer>
      <PriceContainer>
        {showItemPrice(item, showLiningPrice, liningPrice)}
      </PriceContainer>
    </Choice>
  );
}

const Alterations = ({ clothRef, openedCategory, openCategory, submitCloth, submitLast, toggleOrSetAlteration,
  cloth, isLastCloth }) => (
  // eslint-disable-next-line
  <Categories>
    {clothRef.categories
      .filter(category => !featureSwitch('alterations_hideEmbroidery') || category.name !== 'Broderie main')
      .map(category =>
        <div key={category.name}>
          <Category
            onClick={() => openCategory(category.name)}
            isOpened={openedCategory === category.name}
          >
            <Header3>
              <Title4>
                {openedCategory === category.name ?
                  <img src="https://icon.now.sh/chevron/up/12" alt="chevron icon" />
                  : <img src="https://icon.now.sh/chevron/down/12" alt="chevron icon" />
                }
                {` ${category.name.toLocaleLowerCase()}`}
              </Title4>
            </Header3>
          </Category>
          {openedCategory === category.name &&
            category.items
              .filter(item => item && !item.hidden)
              .filter(item => (clothRef.types.length === 0) || (item.type === cloth.clothType))
              .map(item => renderItem(item, toggleOrSetAlteration, cloth, category.mostCommonCategory))}
        </div>,
      )}
    {isLastCloth ?
      <ButtonContainer>
        <Button onClick={submitLast}>{'suivant'}</Button>
      </ButtonContainer> :
      <ButtonContainer>
        <Button onClick={submitCloth}>{'vêtement suivant'}</Button>
      </ButtonContainer>
    }
  </Categories>
);

Alterations.propTypes = {
  clothRef: PropTypes.shape({}).isRequired,
  openCategory: PropTypes.func.isRequired,
  openedCategory: PropTypes.string,
  submitCloth: PropTypes.func.isRequired,
  submitLast: PropTypes.func.isRequired,
  toggleOrSetAlteration: PropTypes.func.isRequired,
  cloth: PropTypes.shape({}).isRequired,
  isLastCloth: PropTypes.bool,
};

export default Alterations;
