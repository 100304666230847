import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import OrderContainer from '../../common/OrderContainer';
import Step2 from '../../../components/Step2v1';
import { getTotal, getClothes } from '../orderReducer';
import { trackEvent } from '../../../services/analytics';
import featureSwitch from '../../../services/featureSwitch';
import pricing from '../../../services/pricing';
import routes from '../../../Routes';

class AlterationsContainer extends Component {
  constructor() {
    super();
    this.state = {};
    this.openCategory = this.openCategory.bind(this);
    this.setChoicesCloth = this.setChoicesCloth.bind(this);
    this.setClothType = this.setClothType.bind(this);
    this.addLining = this.addLining.bind(this);
    this.removeLining = this.removeLining.bind(this);
    this.goNext = this.goNext.bind(this);
    this.deleteCloth = this.deleteCloth.bind(this);
    this.toggleOrSetAlteration = this.toggleOrSetAlteration.bind(this);
    this.setNextClothChoices = this.setNextClothChoices.bind(this);
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    if (featureSwitch('step2_openCurtainCategory')) {
      const clothRef = pricing.find(clothIt => clothIt.slug === 'deco');
      this.setChoicesCloth(clothRef, this.props.clothes[0], 0, 'Rideaux');
    } else {
      this.setFirstClothChoices();
    }
  }

  setFirstClothChoices() {
    const { clothes } = this.props;
    if (clothes.length === 0) return;
    const slug = clothes[0].slug;
    const clothRef = pricing.find(clothIt => clothIt.slug === slug);
    this.setState({
      showChoicesClothRef: clothRef,
      showChoicesClothIndex: 0,
      openedCategory: clothRef.categories[0].name,
    });
  }

  setNextClothChoices() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    const { clothes } = this.props;
    if (clothes.length === 0) return;
    const index = this.state.showChoicesClothIndex + 1;
    const slug = clothes[index].slug;
    const clothRef = pricing.find(clothIt => clothIt.slug === slug);
    this.setState({
      showChoicesClothRef: clothRef,
      showChoicesClothIndex: index,
      openedCategory: clothRef.categories[0].name,
    });
  }

  setChoicesCloth(clothRef, cloth, index, openedCategory) {
    if (clothRef) {
      trackEvent('ouvrir-retouches', clothRef.slug);
    }
    this.setState({
      showChoicesClothRef: clothRef,
      showChoicesClothIndex: index,
      openedCategory: openedCategory || clothRef.categories[0].name,
    });
  }

  setClothType(clothIndex, clothType) {
    trackEvent('changer_cloth_type', clothType);
    this.props.dispatch({ type: 'ORDER.SET_CLOTH_TYPE', clothIndex, clothType });
  }

  addLining(clothIndex) {
    this.props.dispatch({ type: 'ORDER.ADD_LINING', clothIndex });
  }

  removeLining(clothIndex) {
    this.props.dispatch({ type: 'ORDER.REMOVE_LINING', clothIndex });
  }

  openCategory(openedCategory) {
    trackEvent('ouvrir-categorie', openedCategory);
    this.setState({ openedCategory: openedCategory === this.state.openedCategory ? null : openedCategory });
  }

  toggleOrSetAlteration(clothIndex, alterationId, quantity) {
    trackEvent('toggle-retouche', alterationId);
    if (typeof quantity === 'number') {
      this.props.dispatch({ type: 'ORDER.SET_ALTERATION_QUANITY', clothIndex, alterationId, quantity });
    } else {
      this.props.dispatch({ type: 'ORDER.TOGGLE_ALTERATION', clothIndex, alterationId });
    }
  }

  deleteCloth(clothIndex, slug) {
    trackEvent('enlever-panier-etape2', slug);
    this.props.dispatch({ type: 'ORDER.DELETE_CLOTH', clothIndex });
  }

  goNext({ validation = true }) { // eslint-disable-line class-methods-use-this
    if (!validation) { trackEvent('click', 'passer-estimation'); }
    if (featureSwitch('fabrics_skip')) {
      navigate(routes.EstimationVariantInfos.url);
      return;
    }
    navigate(routes.EstimationVariantFabrics.url);
  }

  isOrderValid() {
    return this.props.clothes && this.props.clothes.length > 0
      && !this.props.clothes.find(cloth => !cloth.alterations || cloth.alterations.length === 0);
  }

  render() {
    const isButtonValid = this.isOrderValid();
    return (
      <div>
        <OrderContainer>
          <Step2
            clothes={this.props.clothes}
            total={this.props.total}
            toggleOrSetAlteration={this.toggleOrSetAlteration}
            showChoicesClothRef={this.state.showChoicesClothRef}
            showChoicesCloth={this.props.clothes[this.state.showChoicesClothIndex]}
            showChoicesClothIndex={this.state.showChoicesClothIndex}
            openedCategory={this.state.openedCategory}
            openCategory={this.openCategory}
            setClothType={this.setClothType}
            submitCloth={this.setNextClothChoices}
            setChoicesCloth={this.setChoicesCloth}
            isButtonValid={isButtonValid}
            goNext={this.goNext}
            hasError={this.state.hasError}
            deleteCloth={this.deleteCloth}
            addLining={this.addLining}
            removeLining={this.removeLining}
          />
        </OrderContainer>
      </div>
    );
  }
}

AlterationsContainer.propTypes = {
  clothes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatch: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  clothes: getClothes(state),
  total: getTotal(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlterationsContainer);
